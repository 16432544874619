import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import Checkbox from './ui/Checkbox';
import styled from '@emotion/styled';
import SettingService from "../services/SettingService";
import Input from "./ui/Input";
import AlertStepsWrapper from "./AlertStepsWrapper";
import {getUserColor} from '../Utils/Commons';
import Toast from "./ui/Toast";

const Wrapper = styled.div`
    padding: 5px;
`

const Error = styled.div`
    font-size: 12px;
    color: red;
    padding-top: 5px
`

const Success = styled.div`
    font-size: 12px;
    color: green;
    padding-top: 5px
`

const StepOption = styled.div`
    display: flex;
    flex-direction: row;
`

const StepLabel = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`

const RowDiv = styled.div`
    display: flex;
    flex-direction: row;
`

const Settings = () => {
    const [settings, setSettings] = useState([]);
    const [formValid, setFormValid] = useState(true);
    const [formErrors, setFormErrors] = useState([]);
    const {t} = useTranslation();
    const primaryColor = getUserColor();
    const [toastState, setToastState] = useState({
        open: false,
        vertical: 'top',
        horizontal: 'center',
        message: `${t('settings.parameters')} ${t('settings.settingSteps.save')} !`,
        severity: 'success',
    });

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        const settingService = new SettingService();
        const settingData = await settingService.getSettings();

        setSettings(settingData);
    }

    const changeSetting = (value, setting) => {
        const updatedSettings = [...settings];

        updatedSettings.forEach(s => {
            if (s.type === setting) {
                s.setting = value;
            }
        })

        setSettings(updatedSettings);
    }

    const handleActivatedAlertChange = () => {
        const updatedSettings = [...settings];

        updatedSettings.forEach(s => {
            if (s.type === "alert_active") {
                s.setting = !s.setting;
            }
        })

        setSettings(updatedSettings);
    }

    const getSettingValue = (setting) => {
        return settings?.find(s => s.type === setting)?.setting || 0;
    }

    const saveSettings = async () => {
        const settingService = new SettingService();
        const res = await settingService.saveSettings(settings);

        if (!res.success) {
            setFormValid(false);
            setFormErrors([res.message]);
            return;
        }

        setToastState({...toastState, open: true});
    }

    return (
        <Wrapper>
            <Toast toastState={toastState} setToastState={setToastState} />
            {/* Min alert time */}
            <RowDiv>
                <div style={{paddingBottom: '10px'}}>
                    <Input onChange={(v) => changeSetting(v, "alert_time")}
                           placeholder={t('settings.settingSteps.stepTimePlaceholder')} type="number"
                           value={getSettingValue("alert_time")} label={t('settings.settingSteps.stepTimeLabel')}/>
                </div>
                <div style={{paddingBottom: '10px'}}>
                    <Input onChange={(v) => changeSetting(v, "close_call_time")}
                           placeholder={t('settings.settingSteps.closeTimePlaceHolder')} type="number"
                           value={getSettingValue("close_call_time")}
                           label={t('settings.settingSteps.closeTimeLabel')}/>
                </div>
            </RowDiv>
            {/* Alert active */}
            <div style={{paddingBottom: '10px'}}>
                <Checkbox
                    label={t('settings.settingSteps.stepAlertActive')}
                    value={getSettingValue("alert_active")}
                    onChange={() => handleActivatedAlertChange()}
                    color={primaryColor}
                />
            </div>
            <div style={{paddingBottom: '10px', color: "red"}}>
                { t('settings.settingSteps.howTo') }
            </div>
            <AlertStepsWrapper settings={settings} setSettings={setSettings} saveSettings={saveSettings}/>
            {!formValid && formErrors.map((error, i) => (
                <Error key={i}>{error}</Error>
            ))}
        </Wrapper>
    )
}

export default Settings;