import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import { Document, Page } from 'react-pdf'
import {getUserSecondaryColor, userHasPermission} from "../Utils/Commons";
import {faFileUpload} from "@fortawesome/free-solid-svg-icons";
import {Typography} from "@mui/material";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {getToken} from '../Utils/Commons';
import axios from "axios";
import Toast from "../components/ui/Toast";

const CalendarPdfPage = () => {
    const { t } = useTranslation();
    const [selectedFile, setSelectedFile] = useState(null);
    const [file, setFile] = useState('./uploads/GrilleHoraire.pdf');
    const [numPages, setNumPages] = useState(1);
    const [toastState, setToastState] = useState({
        open: false,
        vertical: 'top',
        horizontal: 'center',
        message: `${t('calendar.pdf.title')} - ${t('calendar.pdf.save')} !`,
        severity: 'success',
    });

    // Handle file selection
    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    // Handle file upload
    const handleUpload = async () => {
        if (!selectedFile) {
            alert("Please select a file first!");
            return;
        }

        const formData = new FormData();
        formData.append("file", selectedFile);
        formData.append("jwt", getToken());

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}api/CalendarUpload.php`,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                });

            setToastState({...toastState, open: true});
            setTimeout(() => {
                window.location.reload();
            }, 500);
        } catch (error) {
            console.error("Upload Error:", error.response?.data || error.message);
            let toastData = {...toastState};
            toastData.message = `${t('calendar.pdf.title')} - ${t('calendar.pdf.error')} !`;
            toastData.severity = 'error';

            setToastState({...toastData, open: true});
        }
    };

    const isFilePDF = () => {
        return selectedFile !== null && selectedFile.type === "application/pdf";
    }

    const onDocumentLoadSuccess = (pdf) => {
        if (pdf === undefined || pdf === null) { return 1; }
        setNumPages(pdf.numPages ?? 1);
    }

    return (
        <>
            <Toast toastState={toastState} setToastState={setToastState}/>
            {userHasPermission('canSeeConfig') &&
                <div style={{backgroundColor: getUserSecondaryColor(), padding: '5px'}}>
                    <Box sx={{display: "flex", flexDirection: "row", gap: 2, textAlign: "middle"}}>
                        <input
                            type="file"
                            id="file-upload"
                            style={{display: "none"}}
                            onChange={handleFileChange}
                        />

                        <label htmlFor="file-upload">
                            <Button variant="contained" component="span">
                                <FontAwesomeIcon icon={faFileUpload}/>&nbsp;
                                {t('calendar.pdf.choose')}
                            </Button>
                        </label>

                        {selectedFile && <Typography style={{display: "flex", alignItems: "center"}}>{selectedFile.name}</Typography>}
                        
                        <Button
                            variant="contained"
                            color={isFilePDF() ? "success" : "error"}
                            onClick={handleUpload}
                            disabled={!selectedFile || !isFilePDF()}
                        >
                            {t('calendar.pdf.upload')}
                        </Button>

                        {selectedFile && !isFilePDF() && <Typography style={{display: "flex", alignItems: "center", color: "red"}}>
                            {t('calendar.pdf.errorType')}
                        </Typography>}
                    </Box>
                </div>
            }
            <h4 style={{textAlign: "center", margin: "10px 0"}}>{t('calendar.pdf.title')}</h4>

            <div style={{marginLeft: "1%", width: "98%", height: "90vh", border: "1px solid black"}}>
                <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
                    <Page pageNumber={numPages} />
                </Document>
            </div>
        </>
    )
}

export default CalendarPdfPage;