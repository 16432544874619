import { useEffect, useState } from 'react';
import moment from "moment";
import specialisationType from "../enums/specialisationType"
import styled from '@emotion/styled';
import CalendarUsersList from './CalendarUsersList';
import ModalBackdrop from "./ModalBackdrop";
import TestCallButton from './TestCallButton';
import CalendarRangesDetail from './CalendarRangesDetail';
import CalendarWrapper from './CalendarWrapper';
import UseFetchCalendarRanges from '../hooks/UseFetchCalendarRanges';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenAlt, faTable } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import { getUserColor, userHasPermission } from '../Utils/Commons'
import IconButton from '@mui/material/IconButton';

const Wrapper = styled.div`
  position: absolute;
  right: 0;
  top: 40px;
  margin: 2px;
  border-radius: 3px;
  min-width: 80px;
  padding: 10px;
  z-index: 9;
  box-shadow: -5px 5px 8px 0px rgb(0 0 0 / 25%)
`

const EditIconWrapper = styled(IconButton)`
  position: absolute;
  right: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  &:hover {
    cursor: pointer;
  }
`

const ScheduleIconWrapper = styled(IconButton)`
  position: absolute;
  left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  &:hover {
    cursor: pointer;
  }
`

const CalendarModal = ({ setCalendarVisibility, backgroundColor }) => {
  const defaultRange = {
    startDate: null,
    endDate: new Date(""),
    key: 'selection',
    exceptionSentStatus: 0
  };
  const [currentSpecialisation, setCurrentSpecialisation] = useState(specialisationType.PLC);
  const [currentUser, setCurrentUser] = useState(null);
  const [users, setUsers] = useState([])
  const [selected, setSelected] = useState(moment());
  const [selectedRanges, setSelectedRanges] = useState([]);
  var [ranges, setRanges] = useState([defaultRange]);
  const history = useHistory();
  const primaryColor = getUserColor();

  useEffect(() => {
    fetchData();
  }, [currentSpecialisation])

  const fetchData = async () => {
    const fetchedData = await UseFetchCalendarRanges(currentSpecialisation);

    setUsers(fetchedData.supportTimeSlotsData.users);
    setRanges(fetchedData.ranges);
  }

  const handleSelectedDayUpdate = (date) => {
    if (!userHasPermission('canSeeConfig')) { return; }
    setSelected(date, moment(date.endDate));
  }

  const redirectToDetailPage = () => {
    history.replace('/calendar');
    setCalendarVisibility(false)
  }

  const redirectToSchedule = () => {
    history.replace('/calendarpdf');
    setCalendarVisibility(false)
  }

  return (
    <>
      <ModalBackdrop setStateValue={setCalendarVisibility} />
      <Wrapper style={{ backgroundColor: backgroundColor }}>
        <ScheduleIconWrapper onClick={redirectToSchedule}>
          <FontAwesomeIcon icon={faTable} size="sm" color="black" />
        </ScheduleIconWrapper>
        { userHasPermission('canSeeConfig') &&
          <EditIconWrapper onClick={redirectToDetailPage}>
            <FontAwesomeIcon icon={faPenAlt} size="sm" color={primaryColor} />
          </EditIconWrapper>
        }
        <CalendarWrapper
          ranges={ranges}
          setRanges={setRanges}
          currentUser={currentUser}
          selected={selected}
          handleSelectedDayUpdate={handleSelectedDayUpdate}
          setSelectedRanges={setSelectedRanges}
          currentSpecialisation={currentSpecialisation}
          setCurrentSpecialisation={setCurrentSpecialisation}
        />
        <CalendarRangesDetail ranges={selectedRanges} users={users} exceptions />
        <div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: '5px' }}>
          <CalendarUsersList users={users} currentUser={currentUser} setCurrentUser={setCurrentUser} />
          { userHasPermission('canSeeConfig') &&
              <TestCallButton currentSpecialisation={currentSpecialisation} />
          }
        </div>
      </Wrapper>
    </>
  )
}

export default CalendarModal;