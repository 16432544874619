import React, { useState } from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import {getToken, userHasPermission} from './Utils/Commons';

import Login from './pages/LoginPage';
import ChatPage from './pages/ChatPage';
import Navbar from './components/Navbar';
import ConversationListPage from './pages/ConversationListPage';
import SettingsPage from './pages/SettingsPage';
import ClientPage from './pages/ClientPage';
import FactoryPage from './pages/FactoryPage';
import ResetPaswordPage from './pages/ResetPaswordPage';
import CalendarPage from './pages/CalendarPage';
import AnswererListPage from './pages/AnswererListPage';
import ConversationListHistoryPage from "./pages/ConversationListHistoryPage";
import TestConnectionPage from "./pages/TestConnectionPage";
import RapportIntervention from "./components/form/RapportIntervention";
import TestConnectionHistoryPage from "./pages/TestConnectionHistoryPage";
import InterventionReportHistory from "./pages/InterventionReportHistory";
import InterventionReportPage from "./pages/InterventionReportPage";
import AgreementPage from "./pages/AgreementPage";
import CalendarPdfPage from "./pages/CalendarPdfPage";

const App = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Login} />

        <div>
          <PrivateRoute path="/401" permission={null}>
            <Layout>
              <div style={{textAlign: "center"}}>Vous n'avez pas le droit de visiter cette page.</div>
            </Layout>
          </PrivateRoute>
          <PrivateRoute path="/chat/:conversationId?" permission={null}>
            <Layout>
              <ChatPage />
            </Layout>
          </PrivateRoute>
          <PrivateRoute path="/conversations" permission="canAnswerCalls">
            <Layout>
              <ConversationListPage />
            </Layout>
          </PrivateRoute>
          <PrivateRoute path="/historyList" permission="canSeeHistoCalls">
            <Layout>
              <ConversationListHistoryPage />
            </Layout>
          </PrivateRoute>
          <PrivateRoute path="/settings" permission="canSeeConfig">
            <Layout>
              <SettingsPage />
            </Layout>
          </PrivateRoute>
          <PrivateRoute path="/answerers" permission="canSeeConfig">
            <Layout>
              <AnswererListPage />
            </Layout>
          </PrivateRoute>
          <PrivateRoute path="/calendar" permission="canSeeConfig">
            <Layout>
              <CalendarPage />
            </Layout>
          </PrivateRoute>
          <PrivateRoute path="/resetPassword" permission={null}>
            <Layout>
              <ResetPaswordPage />
            </Layout>
          </PrivateRoute>
          <PrivateRoute path="/user/:userId?" permission="canSeeConfig">
            <Layout>
              <ClientPage />
            </Layout>
          </PrivateRoute>
          <PrivateRoute path="/factory/:factoryId?" permission="canSeeConfig">
            <Layout>
              <FactoryPage />
            </Layout>
          </PrivateRoute>
          <PrivateRoute path="/testConnection" permission="canSeeTestConnection">
            <Layout>
              <TestConnectionPage />
            </Layout>
          </PrivateRoute>
          <PrivateRoute path="/rapportIntervention/:conversationId?" permission="canAnswerCalls">
            <Layout>
              <RapportIntervention />
            </Layout>
          </PrivateRoute>
          <PrivateRoute path="/rapportInterventionView/:conversationId?" permission="canSeeHistoIntervention">
            <Layout>
              <InterventionReportPage />
            </Layout>
          </PrivateRoute>
          <PrivateRoute path="/testConnectionHistory" permission="canSeeHistoTest">
            <Layout>
              <TestConnectionHistoryPage />
            </Layout>
          </PrivateRoute>
          <PrivateRoute path="/interventionReportHistory" permission="canSeeHistoIntervention">
            <Layout>
              <InterventionReportHistory />
            </Layout>
          </PrivateRoute>
          <PrivateRoute path="/agreement" permission="canSeeAgreement">
            <Layout>
              <AgreementPage />
            </Layout>
          </PrivateRoute>
          <PrivateRoute path="/calendarpdf" permission="canAnswerCalls">
            <Layout>
              <CalendarPdfPage />
            </Layout>
          </PrivateRoute>
        </div>
      </Switch>
    </Router>
  );
}

const Layout = ({ children }) => {
  const [refetchStatus, setRefetchStatus] = useState(false);
  const [noteVisibility, setNoteVisibility] = useState(false);
  const [rapportVisibility, setRapportVisibility] = useState(false);
  return (
    <div>
      <Navbar setRefetchStatus={setRefetchStatus} setNoteVisibility={setNoteVisibility} noteVisibility={noteVisibility} rapportVisibility={rapportVisibility}/>
      <div>{React.cloneElement(children, {refetchStatus, setRefetchStatus, noteVisibility, setNoteVisibility, rapportVisibility, setRapportVisibility})}</div>
    </div>
  );
};

function PrivateRoute ({ children, permission, ...rest }) {
  const token = getToken();
  const userHasPerm = permission !== null ? userHasPermission(permission) : true;

  return (
    <Route
      {...rest}
      render={({ location }) =>
        token != null && userHasPerm ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: !userHasPerm && token != null ? "/401" : "/",
              state: { from: !userHasPerm && token != null ? undefined : location.pathname}
            }}
          />
        )
      }
    />
  );
}

export default App;