import React, { useEffect, useState } from "react"
import { w3cwebsocket as W3CWebSocket } from "websocket";
import { useParams, useHistory } from "react-router-dom";
import styled from '@emotion/styled';
import {useTranslation} from "react-i18next";

// Components
import UseSendSocket from '../hooks/useSendSocket';
import socketMessageType from '../enums/socketMessageType';
import statusEnum from '../enums/statusEnum';
import ChatWrapper from '../components/ChatWrapper';
import ChatMessageInput from '../components/ChatMessageInput';
import {
  getUser,
  getUserSecondaryColor,
  userHasPermission
} from "../Utils/Commons"
import ConversationService from "../services/ConversationService";
import ConversationStatusService from "../services/conversationStatusService";
import AuthentificationService from "../services/authentificationService";
import DemandeurBanner from "../components/DemandeurBanner";
import StatusBanner from "../components/StatusBanner";
import WebSockeStatusIndicator from "../components/WebSockeStatusIndicator";
import NewChatForm from "../components/NewChatForm";
import {messageMaxLength, listOfIntervals} from "../global_variables";
import NoteService from "../services/NoteService";
import RapportInterventionService from "../services/RapportInterventionService";
import UseValidateToken from "../hooks/useValidateToken";

// Initiate socket connection
const clientUrl = `${process.env.REACT_APP_WEBSOCKET_URL}`;
let client = null;

const Wrapper = styled.div(
  {
    overflow: 'auto',
    position: 'fixed',
    width: '100%',
    bottom: '45px'
  }, props => ({top: props.hasBanner ? '195px' : '125px'})
)

const ChatPage = ({refetchStatus, setRefetchStatus, noteVisibility, setRapportVisibility} ) => {
  // Page state
  const [conversationId, setConversationId] = useState(useParams().conversationId || 0);
  const [lastConversationData, setLastConversationData] = useState(null);
  const [userId, setUserId] = useState(null);
  const [conversation, setConversation] = useState(null);
  const [chatMessages, setChatMessages] = useState([]);
  const [currentMessage, setCurrentMessage] = useState("");
  const [socketStatus, setSocketStatus] = useState(false);
  const [lastMessageSeen, setLastMessageSeen] = useState(true);
  const [noteInfo, setNoteInfo] = useState([]);
  const [rapportInfo, setRapportInfo] = useState([]);
  const [resolvedBy, setResolvedBy] = useState(null);
  const history = useHistory();
  const secondaryColor = getUserSecondaryColor();
  const { t } = useTranslation();

    useEffect(() => {
        if (UseValidateToken()) {
            if (userHasPermission("canMakeCalls")) {
                getLatestConversationId();
            }
        }
    }, []);

    const getLatestConversationId = async () => {
        const conversationService = new ConversationService();
        const latestActiveConversation = await conversationService.getLatestConversation();

        if (latestActiveConversation.conversationDetails?.conversationId !== null){
            setLastConversationData(latestActiveConversation.conversationDetails);
        }

        redirectUser(latestActiveConversation.lastConversationId);
    }

    const redirectUser = (conversationId) => {
        if ((history.location.pathname === '/chat' || history.location.pathname === '/chat/') && conversationId > 0){
            history.push(`/chat/${conversationId}`);
            history.go(0);
        }
    }

  useEffect(() => {
    validateUser();
    if (conversationId !== 0) {
      fetchData();
      if(client && userId != null) {
        client.close();
        client = null
        connect();
      }
    }
  }, [conversationId]);

  useEffect( () => {
    let intervalID;
    if (!lastMessageSeen) {
      let updated = false;

      intervalID = setInterval(() => {
        document.title = !updated ? t('chat.newMesageNotification1') : t('chat.newMesageNotification2');
        updated = !updated;
      }, 2000)
    }
    return () => {
      clearInterval(intervalID);
      document.title = "Solu360";
    }
  }, [lastMessageSeen]);

  useEffect(() => {
    conversationEnded();
  }, [conversation])

  useEffect(() => {
    // Connect to websocket
    if(userId != null){
      connect();
    }
    return () => {
      client && client.close();
      client = null;
    }
  }, [userId]);

  useEffect(() => {
    if(refetchStatus) {
      UseSendSocket(client, userId, socketMessageType.STATUS_UPDATE, conversationId);
      setRefetchStatus(false);
    }
  }, [refetchStatus])

  useEffect(async () => {
    if (!noteVisibility) {
      await fetchNote();
    }
  }, [noteVisibility])

  useEffect(() => {
    if(client != null) {
      client.onmessage = (message) => {
        let initMessages = chatMessages;
        const parsedContent = JSON.parse(message.data);
  
        // Update conversation id
        if(parsedContent.type === socketMessageType.CONVERSATION_ID_UPDATE) {
            setConversationId(parsedContent.message.toString());
            UseSendSocket(client, userId, socketMessageType.LOGIN, parsedContent.message.toString());
            history.push("/chat/"+parsedContent.message);

            return;
        }

        // Update status
        if(parsedContent.type === socketMessageType.STATUS_UPDATE) {
          fetchConversationStatus();

          return;
        }

        if(userId !== parsedContent.from.userId) {
          startNotifications();
        }

        const formatedMessage = {
          conversationId,
          userId: parsedContent.from.userId,
          message: parsedContent.message,
          createdAt: Date.now(),
          user: parsedContent.userData
        };

        initMessages.push(formatedMessage);
        setChatMessages([...initMessages]);
      };

      client.onclose = function(e) {
        // If we left the page normally, and it did not close by something else breaking.
        if(e.wasClean) clearAllIntervals();
        else if(listOfIntervals.length === 0) startReconnectInterval();

        console.log('Socket is closed. Reconnect will be attempted in 5 second.');
        setSocketStatus(WebSocket.CLOSED);
      };

      client.onerror = function() {
        clearAllIntervals();
        if(client !== null){client.close();}
        setSocketStatus(WebSocket.CLOSED);
      };
    }
  }, [client, chatMessages])

  const fetchConversationStatus = async() => {
    const conversationStatusService = new ConversationStatusService();
    const status = await conversationStatusService.getConversationStatus(conversationId);

    const updatedConversation = {...conversation};
    updatedConversation.status = status;
    setConversation(updatedConversation);

      if (!userHasPermission("canMakeCalls")) {
          await fetchRapport(status);
      }
  }

  const validateUser = async() => {
    // Get the client id from the JWT token
    const authentificationService = new AuthentificationService();
    const tokenUserId = await authentificationService.getClientId();
    setUserId(tokenUserId);
  }

  const conversationEnded = () => {
    //Redirect if client and conversation resolved
    const isResolved = conversation?.status.find(cs => cs.status === statusEnum.RESOLVED);
    if(isResolved && userHasPermission("canMakeCalls")) {
      client && client.close();
      history.push("/")
    }
  }

  const fetchData = async() => {
    // Get the messages history for the conversation and update the messages state.
    const conversationService = new ConversationService();
    var conversationData = await conversationService.getConversation(conversationId);

    if(conversationData) {
      var {messages: messagesData, ...conversationData} = conversationData;

      setConversation(conversationData);
      setChatMessages(messagesData);

      if (conversationData.userId === conversationData.resolvedBy) {
          setResolvedBy(conversationData.user.name);
      }
    }

      if (!userHasPermission("canMakeCalls")) {
          await fetchNote();
          await fetchRapport(conversationData.status);
      }
  }

  const fetchNote = async () => {
    const noteService = new NoteService();
    const noteData = await noteService.getNote(conversationId);
    setNoteInfo(noteData);
  }

  const fetchRapport = async (status) => {
    const rapportInterventionService = new RapportInterventionService();
    const rapportData = await rapportInterventionService.getRapportByConversationId(conversationId);
    setRapportInfo(rapportData);

    if(rapportData === "" && status.find(c => c.status === statusEnum.RESOLVED) !== undefined) {
      setRapportVisibility(true);
    } else {
      setRapportVisibility(false);
    }
  }

  const startNotifications = () => {
    setLastMessageSeen(false);
    makeAlertSound();
  }

  const makeAlertSound = () => {
    const alertUrl = process.env.PUBLIC_URL + '/notificationChat.mp3';
    const audio = new Audio(alertUrl);
    const resp = audio.play();

    // Remove console error
    if (resp!== undefined) {
      resp.then(_ => {
          // autoplay starts!
      }).catch(() => {
         //show error
      });
    }
  }

  async function reconnect() {
    try {
      if(conversationId !== 0) {
        await connect(true);
      }
    } catch (err) {
      console.log("WEBSOCKET_RECONNECT: Error");
    }
  }

  function connect(reconnect = false) {
    if(userId == null || (client != null && !reconnect)) return;

    client = new W3CWebSocket(clientUrl);
    client.onopen = () => {
      UseSendSocket(client, userId, socketMessageType.LOGIN, conversationId);
      setSocketStatus(WebSocket.OPEN);
      console.log("Socket is Connected.");

      // Ping to keep open | Start intervals
      clearAllIntervals();
      sendPing();
      startReconnectInterval();
    }
  }

  function clearAllIntervals() {
    listOfIntervals.forEach(clearInterval);
    listOfIntervals.splice(0, listOfIntervals.length);
  }

  const startReconnectInterval = () => {
    let reconnectIntervalID = setInterval(() => {
      if (client != null && !isSocketOpen(client)) {
        reconnect().then(() => {});
      }
    }, 5000);
    listOfIntervals.push(reconnectIntervalID);
  }

  const sendPing = () => {
    let pingIntervalID = setInterval(() => {
      UseSendSocket(client, 0, socketMessageType.PING, 0, "");
    }, 60000);
    listOfIntervals.push(pingIntervalID);
  }

  function isSocketOpen(websocket){
    return websocket.readyState === websocket.OPEN;
  }

  const handleChatMessageChange = (e) => {
    // Prevent the user from typing if the limit has been reached but allow to remove with backspace.
    if(e.target.value.length > messageMaxLength && e.key !== "Backspace"){
      e.preventDefault();
      return false;
    }

    setCurrentMessage(e.target.value.substring(0, messageMaxLength));
  };

  const handleKeyDownSubmit = (e) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  const handleSubmit = () => {
    const user = getUser();
    const userData = {
      firstname: user.firstname,
      lastname: user.lastname
    }

    if(currentMessage.length === 0) return;

    UseSendSocket(client, userId, socketMessageType.MESSAGE, conversationId, currentMessage, userData);
    setCurrentMessage("");

    // Refresh status if first repondeur message
    if(!userHasPermission("canMakeCalls") && !conversation.status.find(s => s.status === statusEnum.RESPONDED))
    {
      UseSendSocket(client, userId, socketMessageType.STATUS_UPDATE, conversationId);
    }
  };

  const handlePageClick = () => {
    if(!lastMessageSeen) {
      setLastMessageSeen(true);
      UseSendSocket(client, userId, socketMessageType.MESSAGE_READ, conversationId);
    }
  }

  const handleNotifyNewConversation = () => {
    UseSendSocket(client, userId, socketMessageType.NEW_CONVERSATION, 0, "");
  }

  const setCurrentAlertsFalse = () => {
    const updatedConversation = {...conversation};
    updatedConversation.hasActiveAlerts = false;

    setConversation(updatedConversation);
  }

  if(conversationId === 0) {
    return (<NewChatForm setConversationId={setConversationId} handleNotifyNewConversation={handleNotifyNewConversation} lastConversationData={lastConversationData} setLastConversationData={setLastConversationData} />)
  }

  return (
    <div onClick={handlePageClick}>
      {conversation && <StatusBanner statusList={conversation?.status} hasActiveAlerts={conversation?.hasActiveAlerts} secondaryColor={secondaryColor} setCurrentAlertsFalse={setCurrentAlertsFalse} resolvedBy={resolvedBy} />}
      {!userHasPermission("canMakeCalls") && conversation && conversation.user && <DemandeurBanner user={conversation.user} secondaryColor={secondaryColor} noteId={noteInfo.id} rapportId={rapportInfo.id} />}
      <WebSockeStatusIndicator socketStatus={socketStatus} />
      <Wrapper hasBanner={!userHasPermission("canMakeCalls")}>
        <ChatWrapper messages={chatMessages} userId={userId} secondaryColor={secondaryColor} lastMessageSeen={lastMessageSeen} />
      </Wrapper>
      <ChatMessageInput chatMessage={currentMessage} handleChatMesageChange={handleChatMessageChange} handleKeyDownSubmit={handleKeyDownSubmit} handleSubmit={handleSubmit} secondaryColor={secondaryColor} />
    </div>
  );
}

export default ChatPage;